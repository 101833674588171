import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const PartnersStyle = () => {
  const { t } = useTranslation();
  const partners = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(/partners/)/" } }
        sort: { fields: frontmatter___priority }
      ) {
        edges {
          node {
            frontmatter {
              title
              priority
              link
              featuredImage {
                childImageSharp {
                  gatsbyImageData(height: 120, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="customers-area pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <StaticImage
              src="../../assets/images/sun-icon.jpg"
              alt="about"
              style={{ verticalAlign: "middle", marginRight: "5px" }}
              width={32}
              height={32}
            />
            {t("Partner")}
          </span>
          <h2>{t("Current - Partners - Title - 1")}</h2>
          <p>{t("Current - Partners - Text - 1")}</p>
        </div>

        <div className="row">
          {partners.allMarkdownRemark.edges.map((partner) => (
            <div className="col-lg-4 col-md-4" key={Math.random()}>
              <a
                href={partner.node.frontmatter.link}
                target="_blank"
                rel="noreferrer"
              >
                <div className="single-customers-box">
                  <GatsbyImage
                    alt="Partner"
                    image={
                      partner.node.frontmatter.featuredImage.childImageSharp
                        .gatsbyImageData
                    }
                    objectFit="cover"
                    objectPosition="50% 50%"
                    width={200}
                  />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnersStyle;
